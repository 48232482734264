/* Global styles */

/* Apply a natural box layout model to all elements */
*, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
}

/* Make sure the body covers the full viewport and is responsive */
html, body {
    width: 100%;
    height: 100%;
}


html {
    font-size: 16px;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
}

main {
    /* position: relative; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    flex: 1; /* Allows main content to grow and push the footer down */
    background-color: white;
    transition: max-height 0.7s ease;
}

/* Make images responsive */
img {
    max-width: 100%;
    height: auto;
}

section {
    margin-top: 3rem;
    /* overflow: hidden; */
}

a {
    text-decoration: none;
}

a:active,
a:focus,
a:visited {
    text-decoration: none;
    color: inherit;
}

:root {
    /* fonts */
    --font-manrope: Manrope;
    /* --font-space-mono: "Space Mono"; */
    --font-space-mono: "Paytone One";
    --font-roboto: Roboto;
  
    /* font sizes */
    --font-size-xl: 20px;
    --font-size-13xl: 32px;
    --font-size-5xl: 24px;
    --font-size-base: 16px;
    --font-size-29xl: 48px;
    --font-size-17xl: 36px;
  
    /* Colors */
    --cold-grey-white: #fff;
    --color-gray-100: #fffefe;
    --color-gray-200: #fafafa;
    --pallet-1-dark: #1b1c31;
    --color-gray-300: #1e1e1e;
    --color-gray-400: rgba(255, 255, 255, 0.62);
    --color-gray-500: rgba(30, 30, 30, 0.3);
    --color-gray-600: rgba(255, 255, 255, 0.13);
    --color-gray-700: rgba(255, 255, 255, 0.8);
    --color-gray-800: rgba(30, 30, 30, 0.58);
    --color-blueviolet: #5e45fc;
    --color-mediumspringgreen: #45fcba;
    --color-slategray: #64607d;
    --color-darkslategray-100: #424242;
  
    /* Gaps */
    --gap-31xl: 50px;
    --gap-3xs: 10px;
    --gap-38xl: 57px;
    --gap-5xl: 24px;
    --gap-smi: 13px;
    --gap-xl: 20px;
    --gap-7xl: 26px;
    --gap-mini: 15px;
    --gap-4xl: 23px;
  
    /* Paddings */
    --padding-xs: 12px;
    --padding-6xl: 25px;
    --padding-sm: 14px;
    --padding-3xs: 10px;
    --padding-31xl: 50px;
    --padding-12xs: 1px;
    --padding-5xl: 24px;
    --padding-base: 16px;
    --padding-17xl: 36px;
    --padding-7xl: 26px;
    --padding-204xl: 223px;
    --padding-9xl: 28px;
    --padding-13xl: 32px;
  
    /* Border radiuses */
    --br-3xs: 10px;
    --br-xl: 20px;
    --br-4xs: 9px;
    --br-31xl: 50px;
    --br-113xl-5: 132.5px;
    --br-xs: 12px;
  }