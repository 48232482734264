*, :before, :after {
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

html {
  font-size: 16px;
}

body {
  flex-direction: column;
  min-height: 100vh;
  display: flex;
  overflow-x: hidden;
}

main {
  z-index: 2;
  background-color: #fff;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  transition: max-height .7s;
  display: flex;
}

img {
  max-width: 100%;
  height: auto;
}

section {
  margin-top: 3rem;
}

a {
  text-decoration: none;
}

a:active, a:focus, a:visited {
  color: inherit;
  text-decoration: none;
}

:root {
  --font-manrope: Manrope;
  --font-space-mono: "Paytone One";
  --font-roboto: Roboto;
  --font-size-xl: 20px;
  --font-size-13xl: 32px;
  --font-size-5xl: 24px;
  --font-size-base: 16px;
  --font-size-29xl: 48px;
  --font-size-17xl: 36px;
  --cold-grey-white: #fff;
  --color-gray-100: #fffefe;
  --color-gray-200: #fafafa;
  --pallet-1-dark: #1b1c31;
  --color-gray-300: #1e1e1e;
  --color-gray-400: #ffffff9e;
  --color-gray-500: #1e1e1e4d;
  --color-gray-600: #ffffff21;
  --color-gray-700: #fffc;
  --color-gray-800: #1e1e1e94;
  --color-blueviolet: #5e45fc;
  --color-mediumspringgreen: #45fcba;
  --color-slategray: #64607d;
  --color-darkslategray-100: #424242;
  --gap-31xl: 50px;
  --gap-3xs: 10px;
  --gap-38xl: 57px;
  --gap-5xl: 24px;
  --gap-smi: 13px;
  --gap-xl: 20px;
  --gap-7xl: 26px;
  --gap-mini: 15px;
  --gap-4xl: 23px;
  --padding-xs: 12px;
  --padding-6xl: 25px;
  --padding-sm: 14px;
  --padding-3xs: 10px;
  --padding-31xl: 50px;
  --padding-12xs: 1px;
  --padding-5xl: 24px;
  --padding-base: 16px;
  --padding-17xl: 36px;
  --padding-7xl: 26px;
  --padding-204xl: 223px;
  --padding-9xl: 28px;
  --padding-13xl: 32px;
  --br-3xs: 10px;
  --br-xl: 20px;
  --br-4xs: 9px;
  --br-31xl: 50px;
  --br-113xl-5: 132.5px;
  --br-xs: 12px;
}

/*# sourceMappingURL=cgl.99a5089f.css.map */
